<template>
  <v-dialog
    v-bind="$attrs"
    v-on="$listeners"
    class="container"
    width="760"
  >
    <v-card class="container__card d-flex flex-column rounded-lg" width="100%">
      <v-form @submit.prevent="createCode" ref="form">
        <div class="container__item py-5 px-10">
          <p class="container__title">Crear código</p>
        </div>
        <div class="container__item d-flex align-center justify-space-between px-10">
          <div style="width: 45%;">
            <p>Nombre de código</p>
            <v-text-field
              v-model="codeName"
              placeholder="Nombre del código"
              dense
              outlined
              class="container__input"
              :rules="[rules.required, rules.maxLength(15)]"
              :error="errorMessages.codigo!=''"
              :error-messages="errorMessages.codigo"
              @focus="errorMessages.codigo = ''"
            />
          </div>
          <div style="width: 45%;">
            <p>Descuento</p>
            <v-text-field
              v-model="disccount"
              placeholder="Cantidad de descuento (%)"
              dense
              outlined
              class="container__input"
              :rules="[rules.required, rules.percentage(disccount)]"
              :error="errorMessages.porcentaje_descuento!=''"
              :error-messages="errorMessages.porcentaje_descuento"
              @focus="errorMessages.porcentaje_descuento = ''"
            />
          </div>
        </div>
        <div class="container__item py-5 px-9 d-flex justify-end">
          <v-btn @click="closeCreateModal" class="container__btn mx-1">Cerrar</v-btn>
          <v-btn type="submit" :loading="loading" class="container__btn container__btn--save mx-1">Guardar</v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { maxLength, required, percentage } from '@/utils/rules.js'

export default {
  components: {
  },
  data () {
    return {
      codeName: '',
      disccount: '',
      loading: false,
      rules: {
        required,
        maxLength,
        percentage
      },
      errorMessages: {
        codigo: '',
        porcentaje_descuento: ''
      }
    }
  },
  methods: {
    closeCreateModal () {
      this.errorMessages.codigo = ''
      this.errorMessages.porcentaje_descuento = ''
      this.$refs.form.reset()
      this.loading = false
      this.$emit('close')
    },
    async createCode () {
      if (this.$refs.form.validate()) {
        this.loading = true
        try {
          await axios.post('api/admin/matriculas/codigos_referencia/',
            {
                activo: false,
                codigo: this.codeName,
                porcentaje_descuento: this.disccount
            }
          )
          this.loading = false
          this.$emit('close')
          this.$refs.form.reset()
          this.codeName = '',
          this.disccount = ''
        } catch (e) {
          this.errorMessages.codigo = e.response.data.codigo || ''
          this.errorMessages.porcentaje_descuento = e.response.data.porcentaje_descuento || ''
          this.loading = false
        }
        this.$store.state.reload = !this.$store.state.reload
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.border {
  border: 1px solid red !important;
}
.container {
  max-height: 267px !important;
  &__title {
    font-size: 21px;
    font-weight: 600;
    color: #455A64;
    line-height: 31px;
  }
  &__item {
    flex-basis: 33%;
    width: 100%;
  }
  &__btn {
    text-transform: none !important;
    background: #FFF !important;
    border: 1px solid #707070 !important;
    font-size: 16px !important;
    color: #999999;
    box-shadow: none !important;
    &--save {
      background: #009EFB !important;
      color: #FFF;
    }
  }
  &__input {
    height: 68px !important;
  }
}
</style>
